import React, { Component, useEffect, useState } from 'react';
import { selectInit } from './../../services/functions'
import $ from 'jquery'
import AddRfid from './Modal/AddRfid'
import Addtag from './Modal/Addtag'
import history from '../../services/history';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux'
import { getTags } from "./../../state/redux/actions/tags"
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack'
import * as xlsx from 'xlsx';
import moment from 'moment'



function createData(number, vehicle, bay) {
    return { number, vehicle, bay };
}
const StyledTableRow = styled(TableRow)`
background-color: #FF7200;
`
const StyledCell = styled(TableCell)`
color:white;
border-right:1px solid #fff
`
const StyledCell2 = styled(TableCell)`
border-right:1px solid #C5C5C5
`
const rows = [
    createData(1, 'Dl10ee1111', 5),
    createData(2, 'Dl10ee1111', 2),
    createData(3, 'Dl10ee1111', 3),
    createData(4, 'Dl10ee1111', 2)
];



function Tags() {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const tagsData = useSelector(state => state.tags);
    const dispatch = useDispatch();
    function format() {
        let day = format(new Date(tagsData.tags[0].creation_date), "MM/dd/yyyy")
        console.log(day, "//////");
    }

    function _handleClick(id) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    function toogleView(index, check = true) {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table").height()) + 2;
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }
    function _closeModal() {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const downloadTemplate = () => {
        let header=["Tid","TagNo","DisplayName"]
        let workbook = xlsx.utils.book_new()
        let worksheet = xlsx.utils.aoa_to_sheet([header]);
        workbook.SheetNames.push("First");
        workbook.Sheets["First"] = worksheet;
        xlsx.writeFile(workbook, "demo.xlsx");
    }

    useEffect(() => {

        dispatch(getTags())
        console.log(tagsData, "------------")
    }, [])



    return (
        <div className="contentpanel">
            <h1 className="title">RFID</h1>
            <hr />
            <div className="cust-container">
                <div className='cust-row flex-jc-end'>
                <Stack spacing={1} direction="row">
                <div><button className='btn btn-shadow btn-secondary btn-green' onClick={downloadTemplate}><i class="fa fa-download gx-mr-2"></i>Download Sample</button></div>
                <button className='btn btn-shadow btn-secondary' title='Import Tags' onClick={() => { _handleClick('addTagModal') }}>
                                <i className="fa fa-upload gx-mr-2" aria-hidden="true"></i>
                                {/* <input
                                type="file"
                                name="upload"
                                id="upload"
                                onChange={uploadTemplate}
                            /> */}
                                Upload
                            </button>
                    <div className=''>
                        <button className='btn btn-shadow btn-secondary' onClick={() => { _handleClick('addVehicleModal') }} >Add RFID</button>
                    </div>
                    </Stack>
                </div>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledCell>S.No</StyledCell>
                                <StyledCell align='center' width={"15%"}>Tag Number</StyledCell>
                                <StyledCell align='center' width={"20%"}>Status</StyledCell>
                                <StyledCell align='center' width={"20%"}>Display Name</StyledCell>
                                <StyledCell align='center' width={"25%"}>Added on</StyledCell>
                                <StyledCell align='center' width={"25%"}>Modified on</StyledCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {tagsData && (tagsData.tags.map((row, index) => (
                                <TableRow key={row.number}>
                                    <StyledCell2 component="th" scope="row">
                                        {index + 1}
                                    </StyledCell2>
                                    <StyledCell2 align='center' width={"15%"}>{row.TagNo}</StyledCell2>
                                    <StyledCell2 align='center' width={"20%"}>{row.Status}</StyledCell2>
                                    <StyledCell2 align='center' width={"20%"}>{row.DisplayName}</StyledCell2>
                                    <StyledCell2 align='center' width={"25%"}>{moment(row.creation_date).format('DD/MM/YYYY')}</StyledCell2>
                                    <StyledCell2 align='center' width={"25%"}>{moment(row.modified_on).format('DD/MM/YYYY')}</StyledCell2>
                                </TableRow>

                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <AddRfid closeModal={_closeModal} toogleView={toogleView} />
            <Addtag closeModal={_closeModal} toogleView={toogleView} />
        </div>
    )

}



export default Tags;