import {
    ADD_CONFIG,
    GET_CONFIG,
    EDIT_CONFIG
} from "../actions/types";

const intialState = {
    configs: []
};

const configsReducer = (state = intialState, { type, payload }) => {
    // console.log(payload, "///////////");
    switch (type) {
        case GET_CONFIG:
            return { ...state, configs: payload || [] };
        case ADD_CONFIG:
            return { ...state, configs: payload || [] };
            case EDIT_CONFIG:
            return { ...state, configs: payload || [] };
        default:
            return state;
    }
};
export default configsReducer;

// window.location.href = 'data:application/octet-stream;base64,' + img;