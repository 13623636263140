import { post } from "./../../../services/requests";
import { GET_ANALYTICS } from "./types";


export const getAnalytics = (obj) => {
    return (dispatch) => {
        post("vehicle/analyticsData", obj)
            .then(response => {
                // console.log('res', response.data)
                dispatch({ type: GET_ANALYTICS, payload: response.data });

            })
            .catch(err => {
                console.log('res', err)
            })
    }
};