import { combineReducers } from "redux";
import vehicles from './vehicles'
import tags from './tags'
import configs from './settings'
import excel from './download'
import analytics from './analytics'
import loginReducer from "./login";

// const reducers = combineReducers({
//     vehicles: vehicles
// })

// export default reducers; 

export default combineReducers({
    vehicles,
    tags,
    configs,
    excel,
    analytics,
    loginReducer
})