import React, { Component, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getTags, allocateTag } from '../../../state/redux/actions/tags';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { data } from 'jquery';

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`
const StyledSelect = styled.select`
    width:165px;
    height:35px
`
const Stylediv = styled.div`
    display: flex;
    justify-content: flex-center;
    margin:10px
`



function Allocation(props) {
    // constructor(props){
    //     super(props)
    //     this._handleFiles=this._handleFiles.bind(this)
    // }

    const tagsData = useSelector(state => state.tags.tags);
    const dispatch = useDispatch();
    const { vehicle } = props;
    const [vehicleD, setVehicleD] = useState({})
    const [selected, setSelected] = React.useState({ id: '', tid: '', bayNumber: '' });
    const [call, setCall] = useState(true)
    const [tags, setTags] = useState()
    const pending = (tag) => {
        return tag.Status === "Pending"
    }
    const handleChange = (event) => {
        selected.tid = event.target.value;
        // console.log(dataForm, "oowwwwwoo");
    };
    const handleBay =(event)=>{
        selected.bayNumber=event.target.value;
    }
    // const handleChange = (event) => {
    //     const name = event.target.name;
    //     const value = event.target.value;
    //     setSelected(values => ({ ...values, [name]: value }))
    //     console.log(selected, "[[[[[");
    // }

    const allocate = () => {
        dispatch(allocateTag(selected))
        props.closeModal()
        // console.log(selected);
    }
    useEffect(() => {
        if (vehicle) {
            setVehicleD(vehicle)
        }
        if (call) {
            dispatch(getTags())

        }
        if (vehicleD) {
            selected.id = vehicleD._id;
            selected.bayNumber = vehicleD.BayNumber
        }
        if (call) {
            setCall(prev => !prev)
        }
        if (tagsData) {
            const available = tagsData.filter(pending)
            setTags(available);
        }
    }, [vehicle, tagsData, call, vehicleD])
    // console.log(tagsData, "hhhhhhhh");
    // console.log(tags, "qqqqqqqq");
    console.log(selected, "KKKKKKKKKKk");
    return (
        <section id="tagAllocationModal" className="custom-modal modal-vehi-add">
            <div className="custom-modal-header">
                <h3>Allocate Tag</h3>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" >
                {vehicleD && (<>

                    <Row >
                        {/* <div className='cust-col-4'>
                            <label>Vehicle Number:{"  "}
                                <input
                                    readOnly
                                    name="id"
                                    value={vehicleD.VehicleNumber}
                                />
                            </label>
                        </div> */}

                        {/* <TextField id="outlined-basic" disabled={true} label="Vehicle Number" name="id" value={vehicleD.VehicleNumber || ""} variant="outlined" size='small' />
                        <TextField id="outlined-basic" disabled={true} label="Bay Number" name="bayNumber" value={vehicleD.BayNumber || ""} variant="outlined" size='small' /> */}
                        <div className="view-row ">
                            <div className='view-ro1 gx-mt-6'>
                                <span className='span-head'>Vehicle Number</span>
                                <span className='span-txt'>{vehicleD.VehicleNumber}</span>

                            </div>

                            {vehicleD.BayNumber ? <div className='view-ro1'>
                                <span className='span-head'>Bay Number</span>
                                <span className='span-txt'>{vehicleD.BayNumber}</span>
                            </div> : 
                            // <TextField
                            //     required
                            //     id="standard-required"
                            //     label="Bay Number"  
                            //     variant="standard"  
                            // />
                            <div className='add-ro1'>
                                <label className='span-head'>Bay Number*</label>
                            <input
                                    id='inpt-pl'
                                    placeholder='Enter bay number'
                                    name="BayNumber"
                                    onChange={handleBay}
                                />
                                </div>}
                        </div>
                        {/* <div className='cust-col-4'>
                            <label>Bay Number:{"  "}
                                <input
                                    readOnly
                                    name="bayNumber"
                                    value={vehicleD.BayNumber}
                                />
                            </label>
                        </div> */}
                    </Row>
                    <Stylediv>
                        {/* <label for="cars">Choose a Tag:</label> */}

                        <StyledSelect name="tid" id="tags" onChange={handleChange}>
                            <option value="" disabled selected>Select a Tag to allocate</option>
                            {tags && (tags.map((tag) => <option value={tag.Tid}>{tag.TagNo}</option>))}

                        </StyledSelect>
                    </Stylediv>

                </>)}
                <div className="row-div add-vehi-button add-vehi-bottom">

                    <button className="add-btn add-button" onClick={allocate}>Add Tag</button>

                </div>
            </div>
        </section>
    )
}



export default Allocation;