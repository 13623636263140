import { post } from "./../../../services/requests";
import { GET_VEHICLES, ADD_VEHICLE, EDIT_VEHICLE,GET_ANALYTICS } from "./types";


export const getVehicles = (obj) => {
    return (dispatch) => {
        post("vehicle/vehiclelist", {...obj,pagesize:"5"})
            .then(response => {
                console.log('res', response)
                dispatch({ type: GET_VEHICLES, payload: response });

            })
            .catch(err => {
                console.log('res', err)
            })
    }
};

export const addVehicle = (obj) => {
    return (dispatch) => {
        post("vehicle/addvehicle", obj)
            .then(response => {
                // console.log('res', response.message)
                dispatch({ type: ADD_VEHICLE });
                dispatch(getVehicles())
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};
export const editVehicle = (obj) => {
    return (dispatch) => {
        post("vehicle/updatevehicle", obj)
            .then(response => {
                console.log('res', response.message)
                dispatch({ type: EDIT_VEHICLE });
                dispatch(getVehicles())
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};