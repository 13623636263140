import React, { Component, useState, useEffect } from 'react';
import { selectInit } from './../../services/functions'
import $ from 'jquery'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import logo from './../../static/images/logo.png'
import moment from 'moment'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import VehicleIcon from './../../static/images/icon/sidebar/updated/vehicles.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getVehicles } from "./../../state/redux/actions/vehicles"
import { getExcel } from '../../state/redux/actions';
import { getAnalytics } from "./../../state/redux/actions/analytics"
import { styled } from '@mui/system';

const StyledTableRow = styled(TableRow)`
background-color: #FF7200;
`
const StyledCell = styled(TableCell)`
color:white;
border-right:1px solid #fff
`
const StyledCell2 = styled(TableCell)`
border-right:1px solid #C5C5C5
`




function Vehicle() {
    const [vehicles, setVehicles] = useState()
    const [date, setDate] = useState();
    const [status, setStatus] = useState({ status: "" });
    const [type, setType] = useState("text")
    const [filter,setFilter]=useState()
    const vehicleData = useSelector(state => state.vehicles);
    const analyticsData = useSelector(state =>state.analytics.analytics)
    const [page, setPage] = useState({ pagesize: "5", page_no: "1" });
    const dispatch = useDispatch();
    function toogleView(index, check = true) {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table").height()) + 2;
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }
    function downloadXl() {
        // setFilter(values=>({...values,"download_type": "excel"}))
        dispatch(getExcel({...filter,download_type: "excel"}))
    }
    function submitDate() {
        dispatch(getVehicles(date))
        console.log("date is this ", date);
    }
    function searchVehicle() {
        dispatch(getVehicles(filter))
        // console.log(vehicleNumber);
    }
    const changeType = () => {
        setType("date")
    }
    const handleDate = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setFilter(values => ({ ...values, [name]: value }))
        setDate(values => ({ ...values, [name]: value }))
        console.log(date, "date")
    }
    function handleStatus(event) {
        console.log(status.status, "l555555555555555555");
        let { value,name } = event.target;
        // setStatus({ status: value })
        setFilter(values => ({ ...values, [name]: value }))
        // dispatch(getVehicles({ status: value }))

    }

    function _onChange(id, value) {
        console.log("id", id, "value", value)
    }
    // function gatedIn(vehicle){
    //     vehicle.GatedInTime aaaaaaaaaaaaaaaaaaaaaaaaaaaaa
    // }
    const [vehicleNumber, setVehicleNumber] = useState({});


    const handleChange = (event) => {
        setFilter(values=>({...values,[event.target.name]:event.target.value}))
        setVehicleNumber({ "vehicleNumber": event.target.value })
        console.log(vehicleNumber, "tttttt");
    }
    useEffect(() => {
        dispatch(getVehicles(page))
        dispatch(getAnalytics())
    }, [page])

    console.log(analyticsData[0]);
    return (
        <div className="contentpanel">
            <div className='flex-jc-sb'>
                <div className='card-div' style={{padding:"7px"}}>
                    <div className='card-row flex-jc-sa'>
                        {<img style={{ height: "50px" }} src={VehicleIcon} />}
                        <h1>{analyticsData[0]?.TotalGatedIn}</h1>
                    </div>
                    <h6>Total Vehicles Gated In</h6>
                </div>
                <div className='card-div'style={{padding:"7px"}}>
                    <div className='card-row flex-jc-sa'>{<img style={{ height: "50px" }} src={VehicleIcon} />}
                        <h1>{analyticsData[0]?.TotalGatedOut}</h1>
                    </div>
                    <h6>Total Vehicles Gated Out</h6>
                </div>
                <div className='card-div'style={{padding:"7px"}}>
                    <div className='card-row flex-jc-sa'>{<img style={{ height: "50px" }} src={VehicleIcon} />}
                    <h1>{ analyticsData[0]?.GatedInToday}</h1>
                    </div>
                    <h6>Total Vehicles In Today</h6>
                </div>
                <div className='card-div' style={{padding:"7px"}}>
                    <div className='card-row flex-jc-sa'>{<img style={{ height: "50px" }} src={VehicleIcon} />}
                    <h1>{analyticsData[0]?.GatedOutToday}</h1>
                    </div>
                    <h6>Total Vehicles Out Today</h6>
                </div>
            </div>
            <h1 className="title">Vehicle</h1>
            <hr />
            <div className="cust-container">
                <div className='cust-row flex-jc-start flex-jc-sb'>
                    {/* <div >
                        <img style={{ height: "50px" }} src={logo} />
                    </div> */}
                    {/* <div className='flex-jc-sb'> */}
                    <Stack spacing={4} direction="row">
                        <Stack spacing={1} direction="row">
                            <TextField id="outlined-basic" label="Search vehicle Number" name="vehicleNumber" variant="outlined" onChange={handleChange} size='small' />
                            {/* <input
                                placeholder="Search vehicle"
                                name="vehicleNumber"
                                onChange={handleChange} /> */}

                            {/* <button className='btn btn-shadow btn-secondary ' onClick={searchVehicle}>Search</button> */}
                        </Stack>
                        <Stack spacing={1} direction="row">
                            <div>
                                {/* <label>Start Date</label> */}
                                <input placeholder="Start date" className='inpt-dt' type={type} onfocus="this.showPicker()" onClick={changeType} id="start" name="start_date" onChange={handleDate} />
                            </div><div>
                                {/* <label>End Date</label> */}
                                <input placeholder="End date" type={type} onClick={changeType} id="start2" name="end_date" onChange={handleDate} className="inpt-dt" />
                            </div>

                            
                        </Stack>
                        <Stack spacing={1} direction="row">
                            <div>

                                <select name="search_by" onChange={handleStatus} style={{ height: '40px', border: '1px solid #80808075', borderRadius: "5px" }}>
                                    <option value="" disabled selected>Status</option>
                                    <option value="GatedIn">Gate In</option>
                                    <option value="GateOut">Gate Out</option>
                                    <option value="BayIn">BayIn</option>
                                </select>
                            </div>
                            {/* <div>
                    
                                        <select  name="search_by" onChange={handleStatus} style={{height:'40px',border:'1px solid #80808075',borderRadius:"5px"}}>
                                            <option value="" disabled selected>Status</option>
                                            <option value="Allocated">Allocated</option>
                                            <option value="Deallocated">Deallocated</option>
                                            <option value="Pending">Pending</option>
                                        </select>
                    </div> */}
                            <button className='btn btn-shadow btn-secondary ' onClick={searchVehicle}>
                            <i class="fa-solid fa-magnifying-glass gx-mr-2 srch-white"></i>Search</button>
                        </Stack>
                    </Stack>
                        {/* <Stack spacing={1} direction="row"> */}
                        <button className='btn btn-shadow btn-secondary btn-green ' onClick={downloadXl}>
                            <i class="fa fa-download gx-mr-2"></i>Download</button>
                        {/* </Stack> */}
                    {/* </div> */}

                </div>


                {vehicleData.vehicles ? 
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                            <StyledCell align='center' >S.No</StyledCell>
                                <StyledCell align="center">Vehicle Number</StyledCell>
                                <StyledCell align="center">Gate In Time</StyledCell>
                                <StyledCell align="center">Bay Location</StyledCell>
                                <StyledCell align="center">Gate Out Time</StyledCell>
                                <StyledCell align="center">Status</StyledCell>
                            </StyledTableRow>
                        </TableHead>

                        {vehicleData && (vehicleData.vehicles
                            // .filter(row => !vehicleNumber || row.VehicleNumber.indexOf(vehicleNumber) === 0)&&moment(row.BayOutTime).format('DD/MM/YYYY')
                            .map((row, index) => (
                                <TableBody>
                                    <TableRow key={row.VehicleNumber}>
                                    <StyledCell2 component="th" scope="row" align='center' >
                                            {(5*(page.page_no - 1))+index + 1}
                                        </StyledCell2>
                                        <StyledCell2 align="center">{row.VehicleNumber}</StyledCell2>
                                        <StyledCell2 align="center">{row.GatedInTime? moment(row.GatedInTime).format('DD/MM/YYYY hh:mm A') : "NA"}</StyledCell2>
                                        <StyledCell2 align="center">{`Bay No ${row.BayNumber} & ${row.BayInTime ?moment(row.BayInTime).format('DD/MM/YYYY hh:mm A') : "NA"} & ${row.BayOutTime ? moment(row.BayOutTime).format('DD/MM/YYYY hh:mm A') : "NA"}`}</StyledCell2>
                                        <StyledCell2 align="center">{row.GateOutTime? moment(row.GateOutTime).format('DD/MM/YYYY hh:mm A') : "NA"}</StyledCell2>
                                        <StyledCell2 align="center">{row.Status || "NA"}</StyledCell2>
                                    </TableRow>
                                </TableBody>
                            )))}
                    </Table>
                    <div className='flex-jc-end'>
                    <Pagination count={Math.ceil(vehicleData.count/5)}
                    defaultPage={1}
                    onChange={(event, page_no) => setPage({ ...page, page_no })}
                    // hideNextButton={true}
                    // hidePrevButton={true}
                /></div>
                </TableContainer>:"no data found"}
                {/* <div className='flex-jc-end'>
                <Pagination count={Math.ceil(vehicleData.count/5)}
                    defaultPage={1}
                    onChange={(event, page_no) => setPage({ ...page, page_no })}
                    // hideNextButton={true}
                    // hidePrevButton={true}
                />
                </div> */}
            </div>

        </div>
    )

}


export default Vehicle;