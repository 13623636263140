import {
    GET_TAGS,
    ADD_TAG,
    ALLOCATE_TAG,
    DEALLOCATE_TAG,
    IMPORT_TAG
} from "../actions/types";
// import axios from "util/Api";
// import ActionButton from "antd/lib/modal/ActionButton";

const intialState = {
    tags: []
};

const tagsReducer = (state = intialState, { type, payload }) => {
    // console.log(payload, "///////////");
    switch (type) {
        case GET_TAGS:
            return { ...state, tags: payload || [] };
        case ADD_TAG:
            return { ...state, tags: payload || [] };
        case IMPORT_TAG:
                return { ...state, tags: payload || [] };    
        case ALLOCATE_TAG:
            return { ...state, tags: payload || [] };
        case DEALLOCATE_TAG:
            return { ...state, tags: payload || [] };
        default:
            return state;
    }
};
export default tagsReducer;