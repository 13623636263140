import React from 'react';

const Navbar = (props) => (
    <div className="navbaru bg-gradient">
        <div className='logo_section'>
            <img src={props.logo} alt=""/>
        </div>
        <div className='menu_section'>

        </div>
        <div className='btn_section'>
            {/* <button className='btn btn-shadow' onClick={()=>props._onLogin()}>Login</button> */}
        </div>
    </div>
);

export default Navbar