import {
    ADD_VEHICLE,
    GET_VEHICLES,
    EDIT_VEHICLE
} from "../actions/types";
// import axios from "util/Api";
// import ActionButton from "antd/lib/modal/ActionButton";

const intialState = {
    vehicles: [],
    count:[]
};

const vehicleReducer = (state = intialState, { type, payload }) => {
    console.log(payload, "///////////");
    switch (type) {
        case GET_VEHICLES:
            return { ...state, vehicles: payload.data || [],count:payload.count };
        case ADD_VEHICLE:
            return { ...state, vehicles: payload || [] };
            case EDIT_VEHICLE:
                return { ...state, vehicles: payload || [] };
        default:
            return state;
    }
};
export default vehicleReducer;