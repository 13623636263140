import history from "../../../services/history";
import { post } from "./../../../services/requests";
import { LOGIN } from "./types";

export const loginApi = (obj) => {
    // console.log(obj,"api calll")
    return (dispatch) => {
        post("user/login", obj)
            .then(data => {
                if (!data.error) {
                    localStorage.setItem('secretkey', data.token)
                    localStorage.setItem('userType', (obj.email))
                    history.push('/dashboard')
                    // dispatch({ type: LOGIN, payload: data.token })
                }else{
                    //show err t
                }

            })
            .catch(error => console.log(error));
    }
}
export const logOut = () => {
    console.log("api calll logoutttttttttt")
    return (dispatch) => {
        localStorage.removeItem('secretkey')
        localStorage.removeItem('userType')
        dispatch({ type: LOGIN, payload: "" })

    }
}