import React, { Component, useState, useEffect } from 'react';
import FileUpload from './../FileUpload'
import { useDispatch, useSelector } from 'react-redux'
import { editVehicle } from "../../../state/redux/actions/vehicles"
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import styled from '@emotion/styled';

const Row = styled.div`
    display: flex;
    justify-content: space-around;
`

const StyledButton = styled.input`
    margin: 10px 20px;
    border-radius: 4px;
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    cursor: pointer;
    &:hover {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
    }
`

function EditVehicle(props) {
    // constructor(props){
    //     super(props)
    //     this._handleFiles=this._handleFiles.bind(this)
    // } 
    const { vehicle } = props;
    const [vehicleD, setVehicleD] = useState({})

    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
        console.log(inputs, "[[[[[");
    }
    let updatedVal = {
        id: inputs._id,
        vehicleNumber: inputs.VehicleNumber,
        bayNumber: inputs.BayNumber,
        productName: inputs.ProductName,
        lrNumber: inputs.LrNumber,
        workType: inputs.WorkType
    }

    console.log(updatedVal, "updated vallllllllll");

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(editVehicle(updatedVal))
        props.closeModal()
    }


    function _handleFiles(files) {
        console.log(files)
    }
    useEffect(() => {
        if (props.vehicle) {
            setVehicleD(vehicle)
            setInputs(vehicle)
        }
    }, [vehicle])
    console.log(vehicleD);

    return (
        <section id="editVehicleModal" className="custom-modal modal-vehi-add " style={{ width: "50%" }}>
            <div className="custom-modal-header">
                <h1>Edit Vehicle</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ maxHeight: "70vh", overflowX: "auto" }}>
                {/*  <div className="cust-row">
                style={{ overflowX: 'auto', maxHeight: '70vh' }}
                    <div className='cust-col-2'></div>
                    <div className='cust-col-6'>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Enter Vehicle Number..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                    Fetch
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <form onSubmit={handleSubmit}> */}
                {/* <div className='add-vehi-div'> */}
                <Stack spacing={2} direction="column">
                        <Stack spacing={1} direction="row">
                        
                            <div className='col-6 add-vehi'>
                                <span>Vehicle Number:</span>
                                <TextField name="VehicleNumber" value={inputs.VehicleNumber} onChange={handleChange} size='small' />


                            </div>
                            <div className='col-6 add-vehi'>
                                <span>Product Name</span>
                                <TextField id="outlined-basic" name="ProductName" value={inputs.ProductName || ""} variant="outlined" onChange={handleChange} size='small' />

                            </div>
                            </Stack>
                            <Stack spacing={1} direction="row">

                            <div className='col-6 add-vehi'>
                                <span>Bay Number:</span>
                                <TextField id="outlined-basic" name="BayNumber" value={inputs.BayNumber || ""} variant="outlined" onChange={handleChange} size='small' />

                            </div>
                            <div className='col-6 add-vehi'>
                                <span>lr Number:</span>
                                <TextField id="outlined-basic" name="LrNumber" value={inputs.LrNumber || ""} variant="outlined" onChange={handleChange} size='small' />

                            </div>
                            </Stack>
                            <Stack spacing={1} direction="row">
                            <div className='col-6 add-vehi'>
                                <span>Work Type:{"  "}</span>
                                <select name="WorkType" onChange={handleChange} value={inputs.WorkType} style={{ height: '40px',width:"211px", border: '1px solid #80808075', borderRadius: "5px" }}>
                                    <option value="" disabled selected>Select work type</option>
                                    <option value="loading">Loading</option>
                                    <option value="unloading">Unloading</option>
                                </select>
                            </div>

                        </Stack>
                    </Stack>

                    <div className="add-vehi-bottom add-vehi-button gx-mt-10">

                        <button type="submit" className="add-btn add-button" onClick={handleSubmit}>Edit Vehicle</button>

                        {/* <button onClick={handleSubmit} className="">Submit</button> */}
                    </div>
                {/* </div> */}
                {/* </div> */}
                {/* </form> */}
                {/* <div className="cust-row">
                    <div className='cust-col-10'>
                        <div className='collapse-view'>
                            <div className='collapsable_icon' onClick={() => { props.toogleView(0) }}></div>
                            <div className='doc_container'>
                                <div className='doc_container_head'>
                                    Upload Vehicle Documents
                                </div>
                                <div className='doc_container_bdy'>
                                    <FileUpload handleFiles={_handleFiles} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </section>
    )
}



export default EditVehicle;