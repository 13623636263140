import React, { Component, useState, useEffect } from 'react';
import FileUpload from './../FileUpload'

import styled from '@emotion/styled';

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
`

const StyledButton = styled.input`
    margin: 10px 20px;
    border-radius: 4px;
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    cursor: pointer;
    &:hover {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
    }
`

function ViewConfigs(props) {
    // constructor(props){
    //     super(props)
    //     this._handleFiles=this._handleFiles.bind(this)
    // }
    const { configs } = props;
    const [configD, setConfigD] = useState({})
    useEffect(() => {
        if (props.configs) {
            setConfigD(configs)
            console.log("got dataaaaaaaaaa", configD);
        }
    }, [configs])

    return (
        <section id="viewconfigsModal" className="custom-modal modal-vehi-add">
            <div className="custom-modal-header">
                <h1>Configrations Details</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" >
                {configD && (
                    <div>
                        <div className="view-row">
                            <div className='view-ro1'>
                                <span className='span-head'>Imei Number</span>
                                <span className='span-txt'>{configD.imei}</span>
                            </div>

                            <div className='view-ro1'>
                                <span className='span-head'>Display Name</span>
                                <span className='span-txt'>{configD.Displayname}</span>
                            </div>
                        </div>
                            
                                {configD.antenna && (configD.antenna.map((ant) => (
                        <div className="view-row">
                                    <>
                                        
                                            <div className='view-ro1'><span className='span-head'>Antenna Number</span>
                                              <span className='span-txt'>{ant.antennanumber}</span>
                                            </div>
                                            <div className='view-ro1'><span className='span-head'>Status</span>
                                              <span className='span-txt'>{ant.status}</span>
                                            </div>
                                            <div className='view-ro1'><span className='span-head'>Action</span>
                                              <span className='span-txt'>{ant.action}</span>
                                            </div>
                                        
                                    </>
                                </div>
                                )))}
                            

                        </div>
                )}
                {/* <div className="row-div">
                    <div className="share-div">
                        <button className="action_btn">Add Vehicle</button>
                    </div>
                </div> */}
            </div>
        </section>
    )
}



export default ViewConfigs;