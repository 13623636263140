import React, { Component, useState } from 'react';
import FileUpload from './../FileUpload'
import { useDispatch, useSelector } from 'react-redux'
import { addConfigs } from "../../../state/redux/actions/settings"
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack'

import styled from '@emotion/styled';

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
`

const StyledButton = styled.input`
    margin: 10px 20px;
    border-radius: 4px;
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    cursor: pointer;
    &:hover {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
    }
`

function AddConfigs(props) {
    // constructor(props){
    //     super(props)
    //     this._handleFiles=this._handleFiles.bind(this)
    // }
    const [inputs, setInputs] = useState({});
    const [antenna, setAntenna] = useState([]);
    // let arr = [];
    const dispatch = useDispatch();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
        // console.log(inputs, "[[[[[");
    }
    // const handleChange = (event) => {
    //     let data= {

    //     }
    // }
    const handleAntenna = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))

        let arr = [];

        for (let i = 0; i < Number(value); i++) {
            arr.push({ antennanumber: i + 1, status: "", action: "" })
        }
        setAntenna(arr)

    }
    const changeAntenna = (event, i) => {
        const name = event.target.name;
        const value = event.target.value;

        // console.log(name, value, i, antenna[i])

        // let act = antenna[i];
        // act[name] = value;
        antenna[i][name] = value;
        // console.log(antenna);
        setAntenna([...antenna]
        //     (ant => {
        //     if (ant.antennanumber === i + 1) {
        //         return { ...ant, [name]: value }
        //     }
        //     return ant
        // })
        )
        // setAntenna(values => ({ ...values }))
        // setAntenna(values=>({...values,antenna[i][name]:value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // setInputs({ ...inputs, antenna:[...antenna] })
        dispatch(addConfigs({ ...inputs, antenna }))
        props.closeModal()
        // console.log({ ...inputs, antenna: [...antenna] })
        // console.log(props.form);
        // dispatch(addVehicle(inputs))kkkkkk
    }


    function _handleFiles(files) {
        console.log(files)
    }
    // console.log(antenna, "iiiiiiiiii");

    return (
        <section id="addconfigsModal" className="custom-modal  modal-vehi-add" style={{width:"50%"}}>
            <div className="custom-modal-header">
                <h1>Add configration</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:"40vh"}}>
                {/*  <div className="cust-row">
                style={{ overflowX: 'auto', maxHeight: '70vh' }}
                    <div className='cust-col-2'></div>
                    <div className='cust-col-6'>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Enter Vehicle Number..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                    Fetch
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <form onSubmit={handleSubmit} style={{overflow:"Hidden"}}>
                    <Stack spacing={2} direction="column">
                        <Stack spacing={1} direction="row">
                            {/* <div >
                                <label>Enter Display Name:{"  "}
                                    <input

                                        name="displayname"
                                        value={inputs.displayname || ""}
                                        onChange={handleChange}
                                    />
                                </label>
                            </div> */}
                              <div className='col-6 add-vehi'>
                                    <span>Enter Display Name</span>
                                        <TextField id="outlined-basic" placeholder="Enter Display Name" name="displayname" required value={inputs.displayname || ""} variant="outlined" onChange={handleChange} size='small' />
                                  
                                </div>
                            {/* <div >
                                <label>Enter Imei Number:{"  "}
                                    <input

                                        name="imei"
                                        value={inputs.imei || ""}
                                        onChange={handleChange}
                                    />
                                </label>
                            </div> */}
                            <div className='col-6 add-vehi'>
                                    <span>Enter Imei Number</span>
                                        <TextField id="outlined-basic" placeholder="Enter Imei Number" name="imei" required value={inputs.imei || ""} variant="outlined" onChange={handleChange} size='small' />
                                  
                                </div>
                        </Stack>


                        <Stack spacing={1} direction="row">
                            {/* <div >
                                <label>Enter Pattern id:{"  "}
                                    <input

                                        name="tidpattern"
                                        value={inputs.tidpattern || ""}
                                        onChange={handleChange}
                                    />
                                </label>
                            </div> */}
                            <div className='col-6 add-vehi'>
                                    <span>Enter Pattern id</span>
                                        <TextField id="outlined-basic" placeholder="Enter Pattern Id" name="tidpattern" required value={inputs.tidpattern || ""} variant="outlined" onChange={handleChange} size='small' />
                                  
                                </div>
                            {/* <div >
                                <label>Enter Power:{"  "}
                                    <input

                                        name="power"
                                        value={inputs.power || ""}
                                        onChange={handleChange}
                                    />
                                </label>
                            </div> */}
                            <div className='col-6 add-vehi'>
                                    <span>Enter Power</span>
                                        <TextField id="outlined-basic" placeholder="Enter Power" name="power" required value={inputs.power || ""} variant="outlined" onChange={handleChange} size='small' />
                                  
                                </div>
                        </Stack>
                        <Stack spacing={1} direction="row">
                            {/* <div >
                                <label>Number of Antenna:{"  "}
                                    <input
                                        type='number'
                                        name="antenna"
                                        value={inputs.antenna || ""}
                                        onChange={handleAntenna}
                                    />
                                </label>
                            </div> */}
                            <div className='col-6 add-vehi'>
                                    <span>Number of Antenna</span>
                                        <TextField id="outlined-basic" placeholder="Antenna Number" name="antenna" required value={inputs.antenna || ""} variant="outlined" onChange={handleAntenna} size='small' />
                                  
                                </div>
                        </Stack>
                        {antenna.map((d, i) => {
                            return <div key={i}>
                                <Stack spacing={1} direction="row">
                                    {/* <label>Antenna Number:{"  "}
                                        <input
                                            type='number'
                                            name="antennanumber"
                                            value={d.antennanumber || ""}
                                            disabled
                                        />
                                    </label> */}
                                    <div className='col-4 '>
                                    <span>Antenna Number</span>
                                        <TextField id="outlined-basic" placeholder='Antenna Number' name="antennanumber" required value={d.antennanumber || ""} variant="outlined" onChange={handleChange} size='small' disabled/>
                                    
                                </div>
                                    {/* <label>Antenna Status:{"  "}
                                        <input
                                            name="status"
                                            value={antenna[i]?.status || ""}
                                            onChange={e => changeAntenna(e, i)}
                                        />
                                    </label> */}
                                    {/* <div className='col-4 '>
                                    <span>Antenna Status:</span>
                                        <TextField id="outlined-basic" placeholder='Status' name="status" required value={antenna[i]?.status || ""} variant="outlined" onChange={e => changeAntenna(e, i)} size='small' />
                                    
                                </div> */}
                                <div className='col-4'>
                                <span>Antenna Status</span>
                                <select name="status" onChange={e => changeAntenna(e, i)} value={antenna[i]?.status || ""} style={{ height: '40px', border: '1px solid #80808075', borderRadius: "5px" }}>
                                    <option value="" disabled selected> Antenna Status</option>
                                    <option value="Enabled">Enabled</option>
                                    <option value="Disabled">Disabled</option>
                                </select>
                            </div>
                                    {/* <label>Antenna Action:{"  "}
                                        <input
                                            name="action"
                                            value={antenna[i]?.action || ""}
                                            onChange={e => changeAntenna(e, i)}
                                        />
                                    </label> */}
                                    {/* <div className='col-4 '>
                                    <span>Antenna Action:</span>
                                        <TextField id="outlined-basic" placeholder='Action' name="action" required value={antenna[i]?.action || ""} variant="outlined" onChange={e => changeAntenna(e, i)} size='small' />
                                    
                                </div> */}
                                <div className='col-4'>
                                <span>Antenna Action</span>
                                <select name="action" onChange={e => changeAntenna(e, i)} value={antenna[i]?.action || ""} style={{ height: '40px', border: '1px solid #80808075', borderRadius: "5px" }}>
                                    <option value="" disabled selected> Antenna Action</option>
                                    <option value="GateIn">GateIn</option>
                                    <option value="Gateout">Gateout</option>
                                    <option value="BayIn">BayIn</option>
                                </select>
                            </div>
                                </Stack>
                            </div>
                        })}

                    </Stack>
                    {/* <div className="cust-row">
                        <div className='cust-col-4'>
                            <label>Enter Work Type:{"  "}
                                <select>
                                    <option value="" disabled selected>Select work type</option>
                                    <option value="loading">Loading</option>
                                    <option value="unloading">Unloading</option>
                                </select>
                            </label>
                        </div>
                    </div> */}
                    <div className="row-div">
                    <div className="share-div gx-mt-10 add-vehi-bottom add-vehi-button">
                            <input type="submit" className="add-btn add-button" />
                        </div>
                    </div>
                </form>

                {/* <div className="cust-row">
                    <div className='cust-col-10'>
                        <div className='collapse-view'>
                            <div className='collapsable_icon' onClick={() => { props.toogleView(0) }}></div>
                            <div className='doc_container'>
                                <div className='doc_container_head'>
                                    Upload Vehicle Documents
                                </div>
                                <div className='doc_container_bdy'>
                                    <FileUpload handleFiles={_handleFiles} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </section>
    )
}



export default AddConfigs;