import React, { Component, useState } from 'react';
import FileUpload from './../FileUpload'
import { useDispatch, useSelector } from 'react-redux'
import { importTag } from "../../../state/redux/actions/tags"
import * as xlsx from 'xlsx';
import noFile from '../../../static/images/icon/noFile.png'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack'
import { Data } from '@react-google-maps/api';


function AddRfid(props) {
    // constructor(props){
    //     super(props)
    //     this._handleFiles=this._handleFiles.bind(this)
    // }
    const [inputs, setInputs] = useState({});
    const [header, setHeader] = useState([])
    const [tabledata, setTableData] = useState([])
    const [url,setUrl]=useState()
    const dispatch = useDispatch();
    const base64={};
   
    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setUrl({attachment:reader.result});
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }
     const uploadFrontImage = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        // setUrl({attachment:base64});
     }
     const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const uploadTemplate = (e) => {
        e.preventDefault();
        let fileObj = e.target.files[0];
        getBase64(fileObj)
        // console.log(fileObj);
        if (fileObj && fileObj.name) {
            let fileExtension = fileObj.name.split('.');
            if (fileExtension[fileExtension.length - 1] === "xls" || fileExtension[fileExtension.length - 1] === "xlsx") {
                if (e.target.files) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const data = e.target.result;
                        const workbook = xlsx.read(data, { type: "array" });
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[sheetName];
                        const result = xlsx.utils.sheet_to_json(worksheet);
                        if (result.length > 0) {
                            setTableData(result)
                            setHeader(Object.keys(result[0]))
                        }
                    };
                    reader.readAsArrayBuffer(e.target.files[0]);
                }
            }
            else{alert('You should only upload excel file!')}
           
        }
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
        console.log(inputs, "[[[[[");
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(url);
        dispatch(importTag(url))
        props.closeModal()
    }


    function _handleFiles(files) {
        console.log(files)
    }


    return (
        <section id="addTagModal" className="custom-modal">
            <div className="custom-modal-header">
                <h1>Add RFID</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="add-excel-body" >
                <div className='preview-div'>{tabledata.length>0 ? 
                <table className='preview-table'>
                        <thead>
                            <tr>
                                {header.map(head => {
                                    return (
                                        <th>{head}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tabledata.map(obj => {
                                return (
                                    <tr>
                                        {header.map((head) => {
                                            return (
                                                <td><span className="gx-mr-2">{obj[head]}</span></td>
                                            )
                                        })
                                        }
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                    // <DataGrid rows={header} columns={tabledata}/>
                    :
                    <div className='n-file'><img style={{ height: "80px" }} src={noFile} /><span>No File Uploaded</span></div>}</div>
                <div className='preview-div-add'><input
                                type="file"
                                name="uploadnew"
                                id="upload"
                                onChange={uploadTemplate}
                                onClick={uploadFrontImage}
                            />
                            {/* <button onClick={handleSubmit}>submit data</button> */}
                            <div className="share-div gx-mt-10 add-vehi-bottom add-vehi-button">
                            <button type="submit" className="add-btn add-button"onClick={handleSubmit} >Submit</button>
                        </div>
                            </div>
            </div>
        </section>
    )
}



export default AddRfid;