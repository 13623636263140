import {
    
    LOGIN
    
} from "../actions/types";
// import axios from "util/Api";
// import ActionButton from "antd/lib/modal/ActionButton";

const intialState = {
    login: localStorage.getItem("secretKey"),
    
};

const loginReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case LOGIN:
            return { ...state, login: payload  };
        default:
            return state;
    }
};
export default loginReducer;