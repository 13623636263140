import { post } from "./../../../services/requests";
import { GET_TAGS, ADD_TAG, ALLOCATE_TAG, DEALLOCATE_TAG,IMPORT_TAG } from "./types";
import { getVehicles } from "./vehicles";


export const getTags = () => {
    return (dispatch) => {
        post("vehicle/rfidlist")
            .then(response => {
                console.log('res', response.data)
                dispatch({ type: GET_TAGS, payload: response.data });
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};

export const addTag = (obj) => {
    return (dispatch) => {
        post("vehicle/addrfid", obj)
            .then(response => {
                console.log('res', response.message)
                dispatch({ type: ADD_TAG });
                dispatch(getTags())
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};
export const importTag = (obj) => {
    return (dispatch) => {
        post("vehicle/importtags", obj)
            .then(response => {
                console.log('res', response.message)
                dispatch({ type: IMPORT_TAG });
                dispatch(getTags())
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};
export const allocateTag = (obj) => {
    return (dispatch) => {
        post("vehicle/allocaterfid", obj)
            .then(response => {
                console.log('res', response.message)
                dispatch({ type: ALLOCATE_TAG });
                dispatch(getVehicles())
                dispatch(getTags())
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};
export const deallocateTag = (obj) => {
    return (dispatch) => {
        post("vehicle/deallocaterfid", obj)
            .then(response => {
                console.log('res', response.message)
                dispatch({ type: DEALLOCATE_TAG });
                dispatch(getVehicles())
                dispatch(getTags())
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};