import FileUpload from './FileUpload'
import OwnerLogo from './../../static/images/icon/vehicle/owner.svg'
import RegistrationLogo from './../../static/images/icon/vehicle/registration.svg'
import EngineLogo from './../../static/images/icon/vehicle/engine.svg'
import ChasisLogo from './../../static/images/icon/vehicle/chasis.svg'
import FuelLogo from './../../static/images/icon/vehicle/fuel.svg'
import React, { useState } from 'react';
import { get_pdf_thumbnail,base64Convert } from './../../services/functions'
import history from '../../services/history'
 
const VehicleDetail = () =>{

    const [fileArr, setFileArr] = useState([]) 

    const _handleFiles = async (files)=>{
        if(files.length > 0){
            let tempArr=[...fileArr]
            let tarr=Array.from(files)
            for(let el of tarr){
                if (el.type.includes('image') || el.type === "application/pdf"){
                    try{
                        let base64 = await base64Convert(el)
                        let obj={base64,fileType:el.type.includes('image')?'image':'pdf',name:el.name}
                       
                        if(obj.fileType==='pdf'){
                            let pdf=base64.replace(/^data:application\/\pdf;base64,/, "")
                            var binary_string =  window.atob(pdf);
                            var len = binary_string.length;
                            var bytes = new Uint8Array( len );
                            for (var i = 0; i < len; i++){
                                bytes[i] = binary_string.charCodeAt(i);
                            }
                            var buffer=bytes.buffer
                            var file3 = new Blob([buffer], {type: 'application/pdf'});
                            obj['dataLocalUrl']=file3
                            get_pdf_thumbnail(file3).then((url)=>{
                                obj['imageSrc']=url
                            })
                            .catch((err)=>{
                                console.log(err)
                            })
                        }
                        tempArr.unshift(obj)                    
                    }
                    catch(err){

                    }
                }
                else{
                    //Swal.fire("Oops","Only image or PDF file is allowed to upload",'warning')
                }
            }
            setFileArr(tempArr)
        }      
    }

    const deleteFile = (index) => {
        let tempArr=[...fileArr]
        tempArr.splice(index,1)
        setFileArr(tempArr) 
    }

    const _goBack = () =>{
        history.push("/dashboard/vehicle")
    }

    // useEffect(()=>{
    //     console.log("hello",fileArr)
    // },[fileArr])

    return(
        <div className="contentpanel">
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Vehicle Detail
                        </h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
                        </button>
                    </div>
                </div>
                <hr className='bgg'/>
                <div className="cust-row">
                    <div className='cust-col-10'>
                        <div className="vd_cont">
                            <div className="vd_cont_1">
                                <div className="vd_cont_1_sec">
                                    <div className="vd_cont_1_sec_icon">
                                        <img src={OwnerLogo} alt="logo"/>
                                    </div>
                                    <div className="vd_cont_1_sec_tt">
                                        Owner Name
                                    </div>
                                    <div className="vd_cont_1_sec_txt">
                                        (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_1_sec">
                                    <div className="vd_cont_1_sec_icon">
                                        <img src={RegistrationLogo} alt="logo"/>
                                    </div>
                                    <div className="vd_cont_1_sec_tt">
                                        Registration No.
                                    </div>
                                    <div className="vd_cont_1_sec_txt">
                                        (Lynkit/OTM)
                                    </div>
                                </div>
                                <div className="vd_cont_1_sec">
                                    <div className="vd_cont_1_sec_icon">
                                        <img src={EngineLogo} alt="logo"/>
                                    </div>
                                    <div className="vd_cont_1_sec_tt">
                                        Engine No.
                                    </div>
                                    <div className="vd_cont_1_sec_txt">
                                        (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_1_sec">
                                    <div className="vd_cont_1_sec_icon">
                                        <img src={FuelLogo} alt="logo"/>
                                    </div>
                                    <div className="vd_cont_1_sec_tt">
                                        Fuel Type
                                    </div>
                                    <div className="vd_cont_1_sec_txt">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_1_sec">
                                    <div className="vd_cont_1_sec_icon">
                                        <img src={ChasisLogo} alt="logo"/>
                                    </div>
                                    <div className="vd_cont_1_sec_tt">
                                        Chasis No
                                    </div>
                                    <div className="vd_cont_1_sec_txt">
                                    (e-Vahan)
                                    </div>
                                </div>
                            </div>
                            <div className="vd_cont_2">
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Transporter's Name
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Father Name
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Vehicle Model
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Vehicle Type
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Registration Date
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Fit Upto
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Cubic Cap
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Gross Vehicle Weight
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Insurance Company
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Insurance Policy No
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Insurance Upto
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Truck Serial No
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (OTM)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        GPS No
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                       (Vendor GPS System)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        Permit Issue Date
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                       (e-Vahan)
                                    </div>
                                </div>
                                <div className="vd_cont_2_sec">
                                    <div className="vd_cont_2_sec_1">
                                        PUC Valid Upto
                                    </div>
                                    <div className="vd_cont_2_sec_2">
                                    (e-Vahan)
                                    </div>
                                </div>
                            </div>
                            <div className="vd_cont_3">
                                <div className="vd_cont_3_sec">
                                    <div className="vd_cont_3_sec_header">
                                        Upload Vehicle Documents
                                    </div>
                                    <div className="vd_cont_3_sec_bdy">
                                        <FileUpload handleFiles={_handleFiles}/>
                                        {fileArr.length?
                                        <div className='file_preview'>
                                            <p>Uploaded Files</p>
                                            <div className='file_preview_cont'>
                                            {
                                                fileArr.map((item,index)=>{
                                                    return <div key={`pp-`+index} className='preview_itm'>
                                                                <div className='closeb' onClick={()=>{deleteFile(index)}}>
                                                                    <i className='fa fa-close'></i>
                                                                </div>
                                                                <img src={item.base64} alt=""/>:
                                                                {/* {
                                                                    item.fileType=='image'?
                                                                    ""
                                                                } */} 
                                                                <div className='fullscreen'>
                                                                    <i className='fa fa-expand'></i>
                                                                </div>
                                                            </div>
                                                })
                                            }
                                            </div>
                                        </div>
                                        :""}
                                        <div className='btn_cont'>
                                            <button className='button bti bg-orange-out m-0'>
                                                <div className='btn_icon'>
                                                    <i className='fa fa-upload'></i>
                                                </div>
                                                <div className='btn_txt font-lg'>Upload Now</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleDetail