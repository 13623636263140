import React, { Component, useState, useEffect } from 'react';
import $ from 'jquery'
import AddVehicle from './Modal/AddVehicle'
import EditVehicle from './Modal/EditVehicle';
import ViewVehicle from './Modal/viewVehicle'
import Allocation from './Modal/allocation'
import Dallocation from './Modal/deallocation';
import history from '../../services/history';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@material-ui/core/IconButton'
import allocate_green from './../../static/images/icon/allocate_green.png'
import deallocate_green from './../../static/images/icon/deallocate_green.png'
import deallocate_red from './../../static/images/icon/deallocate_red.png'
import deallocate_grey from './../../static/images/icon/deallocate_grey.png'
import view_grey from './../../static/images/icon/view_grey.png'
import { useDispatch, useSelector } from 'react-redux'
import { getVehicles } from "./../../state/redux/actions/vehicles"
import { getExcel } from '../../state/redux/actions';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'



const StyledTableRow = styled(TableRow)`
background-color: #FF7200;
`
const StyledCell = styled(TableCell)`
color:white;
border-right:1px solid #fff
`
const StyledCell2 = styled(TableCell)`
border-right:1px solid #C5C5C5
`
function Vehicle() {
    const [vehicles, setVehicles] = useState()
    const [date, setDate] = useState();
    const [status, setStatus] = useState({ status: "" });
    const [type, setType] = useState("text")
    const [filter,setFilter]=useState()
    const [value, setValue] = React.useState([null, null]);
    const [page, setPage] = useState({ pagesize: "5", page_no: "1" });
    const vehicleData = useSelector(state => state.vehicles);
    const vehicleCount = useSelector(state => state.count);
    const excelData = useSelector(state => state.excel.excel);
    const dispatch = useDispatch();
    function toogleView(index, check = true) {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table").height()) + 2;
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }
    function searchVehicle() {
        dispatch(getVehicles(vehicleNumber))
        // console.log(vehicleNumber);
    }
    function downloadXl() {
        // setFilter(values=>({...values,"download_type": "excel"}))
        dispatch(getExcel({...filter,download_type: "excel"}))
        // const downloadLink = document.createElement("a");
        // downloadLink.href = excelData;
        // downloadLink.target = "_blank";
        // downloadLink.click();
        // window.location.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelData
        // console.log('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelData)
    }
    function submitDate() {
        dispatch(getVehicles(date))
        console.log("date is this ", date);
    }
    console.log(vehicleData, "//////////////")
    // data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    // console.log('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelData);
    function toogleItemView(index) {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
        }
        else {
            // let height = Number($(".data-cont-item").eq(index).children(".data_item_content.clps").height()) + 2;
            // $(".data-cont-item")
            //     .eq(index)
            //     .addClass("open")
            //     .animate({ height }, 500, () => {
            $(".data-cont-item").eq(index).addClass("open")
            // });
        }
    }

    function _onChange(id, value) {
        console.log("id", id, "value", value)
    }
    const [vehicleNumber, setVehicleNumber] = useState({});

    const handleChange = (event) => {
        setVehicleNumber({ "vehicleNumber": event.target.value })
        console.log(vehicleNumber, "tttttt");
    }
    const handleDate = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setFilter(values => ({ ...values, [name]: value }))
        setDate(values => ({ ...values, [name]: value }))
        console.log(date, "date")
    }
    const changeType = () => {
        setType("date")
    }
    function tag() {
        console.log("tag allocation");
    }

    function _handleClick(id, row) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
        setVehicles(row)
    }
    function handleStatus(event) {
        console.log(status.status, "l555555555555555555");
        let { value,name } = event.target;
        // setStatus({ status: value })
        setFilter(values => ({ ...values, [name]: value }))
        // dispatch(getVehicles({ status: value }))

    }
    function openView(id) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    function _closeModal() {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    function closeView() {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    function _goto(type) {
        console.log(type)
        if (type === 'detail') {
            history.push("/dashboard/vehicle/details")
        }
    }
    // useEffect(()=>{console.log(filter,"filterdata");},[filter])
    // useEffect(()=>{dispatch(getVehicles(status))},[status])
    useEffect(() => {
        dispatch(getVehicles(page))

    }, [page])

    return (
        <div className="contentpanel">
            <h1 className="title">Vehicle</h1>
            <hr />
            <div className="cust-container">

                <div className='cust-row flex-jc-sb'>
                    <Stack spacing={4} direction="row">

                        <Stack spacing={1} direction="row">
                            <TextField id="outlined-basic" label="Search vehicle Number" name="vehicleNumber" variant="outlined" onChange={handleChange} size='small' />
                            {/* <input
                                placeholder="Search vehicle"
                                name="vehicleNumber"
                                onChange={handleChange} /> */}

                            <button className='btn btn-shadow btn-secondary ' onClick={searchVehicle}>Search</button>
                        </Stack>

                        
                    </Stack>
                    <Stack spacing={1} direction="row">
                        {/* <button className='btn btn-shadow btn-secondary ' onClick={downloadXl}>Download</button> */}
                        <button className='btn btn-shadow btn-secondary ' onClick={() => { _handleClick('addVehicleModal') }} >Add Vehicle</button>
                    </Stack>
                </div>
                <div className='cust-row flex-jc-end'>
                    {/* <a href={''} download onClick={downloadXl}>
                        Download

                    </a> */}

                    {/* <button onClick={downloadXl}>Download</button> */}
                </div>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledCell align='center' width={"15%"}>S.No</StyledCell>
                                <StyledCell align="center" width={"35%"}>Vehicle Number</StyledCell>
                                <StyledCell align="center" width={"20%"}>Bay Number</StyledCell>
                                <StyledCell align="center" width={"30%"}>Actions</StyledCell>
                            </StyledTableRow>
                        </TableHead>

                        {vehicleData && (vehicleData.vehicles
                            // .filter(row => !vehicleNumber || row.VehicleNumber.indexOf(vehicleNumber) === 0)
                            .map((row, index) => (
                                <TableBody>
                                    <TableRow key={row.VehicleNumber}>
                                        <StyledCell2 component="th" scope="row" align='center' width={"15%"}>
                                            {(5 * (page.page_no - 1)) + index + 1}
                                        </StyledCell2>
                                        <StyledCell2 align="center" width={"35%"}>{row.VehicleNumber}</StyledCell2>
                                        <StyledCell2 align="center" width={"20%"}>{row.BayNumber ? row.BayNumber : "n/a"}</StyledCell2>
                                        <StyledCell2 align="center" width={"30%"}>{row.Status === "Allocated" ?
                                            <IconButton onClick={() => { _handleClick('deAllocationModal', row) }}> <img style={{ height: "20px" }} src={deallocate_red} /></IconButton>
                                            : ''}{row.Status === "Pending" ? <IconButton onClick={() => { _handleClick('tagAllocationModal', row) }}> <img style={{ height: "20px" }} src={allocate_green} /></IconButton> : ""}
                                            {row.Status === "Deallocated" ? <IconButton disabled> <img style={{ height: "20px" }} src={deallocate_grey} /></IconButton> : ""}
                                            {<IconButton onClick={() => _handleClick('viewVehicleModal', row)}>{<img style={{ height: "20px" }} src={view_grey} />}</IconButton>}
                                            {row.GateOutTime?"": <IconButton onClick={() => _handleClick('editVehicleModal', row)}>{<i className="fa fa-edit"></i>}</IconButton>}
                                        </StyledCell2>
                                    </TableRow>
                                </TableBody>
                            )))}
                    </Table>
                </TableContainer>
                <div className='flex-jc-end'>
                    <Pagination count={Math.ceil(vehicleData.count / 5)}
                        defaultPage={1}
                        onChange={(event, page_no) => setPage({ ...page, page_no })}
                        // hideNextButton={true}
                        // hidePrevButton={true}
                    />
                </div>
            </div>
            <AddVehicle closeModal={closeView} toogleView={toogleView} />
            <ViewVehicle vehicle={vehicles} closeModal={closeView} toogleView={toogleView} />
            <Allocation vehicle={vehicles} closeModal={closeView} toogleView={toogleView} />
            <EditVehicle vehicle={vehicles} closeModal={closeView} toogleView={toogleView} />
            <Dallocation vehicle={vehicles} closeModal={closeView} toogleView={toogleView} />

        </div>
    )

}


export default Vehicle;