import {
    GET_ANALYTICS
} from "../actions/types";
// import axios from "util/Api";
// import ActionButton from "antd/lib/modal/ActionButton";

const intialState = {
    analytics: []
};

const analyticsReducer = (state = intialState, { type, payload }) => {
    // console.log(payload, "///////////");
    switch (type) {
        case GET_ANALYTICS:
            return { ...state, analytics: payload || [] };
        default:
            return state;
    }
};
export default analyticsReducer;