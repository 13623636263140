/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import combineReducers from './redux/reducers/index';


const store = createStore(combineReducers, applyMiddleware(thunkMiddleware))
export default store;

