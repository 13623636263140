import React,{Component} from 'react';
import FileLogo from './../../static/images/icon/vehicle/upload-file.svg'

class FileUpload extends Component {

    constructor(props){
        super(props)
        this._preventDefault=this._preventDefault.bind(this)
        this.handleDrop=this.handleDrop.bind(this)
        this.uploadImageFromURL=this.uploadImageFromURL.bind(this)
        this.previewAnduploadImage=this.previewAnduploadImage.bind(this)
    }

    componentDidMount() {
    
        // where files are dropped + file selector is opened
        var dropRegion = document.getElementById("ddzone_drop-con")
        // // where images are previewed
        this.imagePreviewRegion = document.getElementById("ddzone_image-preview")
        // open file selector when clicked on the drop region
        var fakeInput = document.createElement("input");
        fakeInput.type = "file";
        fakeInput.accept = "image/*";
        fakeInput.multiple = true;
        dropRegion.addEventListener('click', ()=>{
          fakeInput.click();
        });
    
        fakeInput.addEventListener("change", ()=>{
          var files = fakeInput.files;
          if(this.props.handleFiles){
            this.props.handleFiles(files);
          }
        });
        dropRegion.addEventListener('dragenter', (evt)=>{
          this.preventDefault(evt)
          dropRegion.classList.add('ddzone_hover')
        }, false)
        dropRegion.addEventListener('dragleave', (evt)=>{
            this.preventDefault(evt)
          dropRegion.classList.remove('ddzone_hover')
        }, false)
        dropRegion.addEventListener('dragover',  (evt)=>{
            this.preventDefault(evt)
          dropRegion.classList.add('ddzone_hover')
        }, false)
        dropRegion.addEventListener('drop', (evt)=>{
            this.preventDefault(evt)
          dropRegion.classList.remove('ddzone_hover')
        }, false)
        dropRegion.addEventListener('drop', (e)=>{this.handleDrop(e)}, false);
    }
    
    handleDrop(e) {
        var dt = e.dataTransfer
        var files = dt.files;
        if (files.length) {
            if(this.props.handleFiles){
                this.props.handleFiles(files);
            }
        } 
        else {
          // check for img
          var html = dt.getData('text/html'),
                match = html && /\bsrc="?([^"\s]+)"?\s*/.exec(html),
                url = match && match[1];
          if (url) {
              this.uploadImageFromURL(url);
              return;
          }
        } 
    }
    
    uploadImageFromURL(url) {
        var img = new Image();
        var c = document.createElement("canvas");
        var ctx = c.getContext("2d");
    
        img.onload = ()=> {
            c.width = img.naturalWidth;     // update canvas size to match image
            c.height = img.naturalHeight;
            ctx.drawImage(img, 0, 0);       // draw in image
            c.toBlob((blob)=>{        // get content as PNG blob
    
              // call our main function
                if(this.props.handleFiles){
                    this.props.handleFiles([blob]);
                }
    
            }, "image/png");
        };
        img.onerror = function() {
            alert("Error in uploading");
        }
        img.crossOrigin = "";              // if from different origin
        img.src = url;
    }
    
    previewAnduploadImage(image) {

        // container
        var imgView = document.createElement("div");
        imgView.className = "ddzone_image-view";
        this.imagePreviewRegion.appendChild(imgView);
        
        // previewing image
        var img = document.createElement("img");
        imgView.appendChild(img);
        
        // progress overlay
        var overlay = document.createElement("div");
        overlay.className = "ddzone_overlay";
        imgView.appendChild(overlay);
        
        // read the image...
        var reader = new FileReader();
        reader.onload = (e)=> {
            img.src = e.target.result;
        }
        reader.readAsDataURL(image);
        
    }

    _preventDefault(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    
    render(){
        return(
            <div id="ddzone_drop-region">
                <div className="ddzone_drop-con" id="ddzone_drop-con">
                <img src={FileLogo} alt=""/>
                    <p>
                Drag file here or browse a file to upload
                </p>
                <div>Formats Supported: JPG,PNG,PDF</div>
                </div>
            </div>
        )
    }
}

export default FileUpload;