export const GET_VEHICLES = "GET_VEHICLES";
export const FETCH_ERROR = "FETCH_ERROR";
export const GET_TAGS = "GET_TAGS";
export const ADD_TAG = "ADD_TAG";
export const ADD_VEHICLE = "ADD_VEHICLE";
export const EDIT_VEHICLE = "EDIT_VEHICLE";
export const ALLOCATE_TAG = "ALLOCATE_TAG";
export const DEALLOCATE_TAG = "DEALLOCATE_TAG"
export const GET_CONFIG = 'GET_CONFIG';
export const ADD_CONFIG ='ADD_CONFIG';
export const EDIT_CONFIG ='EDIT_CONFIG';
export const GET_XL ='GET_XL';
export const GET_ANALYTICS ='GET_ANALYTICS';
export const IMPORT_TAG ='IMPORT_TAG';
export const LOGIN = 'LOGIN';