import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Sidebar from "../../views/Dashboard/Sidebar"
import Header from "../../views/Dashboard/Header"
import CustomBreadcrumb from "./../../views/Dashboard/CustomBreadcrumb"
import DashboardView from "../../views/Dashboard/DashboardView"
import Vehicle from "../../views/Dashboard/Vehicle"
import UserProfile from '../../views/Dashboard/UserProfile'
import VehicleDetail from './../../views/Dashboard/VehicleDetail'
import history from './../../services/history'

import './../../static/css/dashboard.css'
import logo from './../../static/images/logo3.png'
import $ from 'jquery'
import Tags from '../../views/Dashboard/Tags';
import Settings from '../../views/Dashboard/Settings'


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this._logout = this._logout.bind(this)
  }
    
  componentDidMount() {
    $("[data-toggle='dropdown']").on("click", function (e) {
      e.stopPropagation();
      let target = $(this).data('target')
      $(target).parent().toggleClass('open')
    });

    setTimeout(() => {
      $(".custom-modal-overlay").height("100vh");
    }, 1000);

    $("[data-toggle!='dropdown']").on("click", function () {
      $(".btn-group,.media").removeClass('open')
    })

    $("#menuToggle").on("click", function () {
      $(".sidebar").toggleClass('open')
    })
  }

  _logout() {
    console.log("oooooo");
    localStorage.removeItem('secretkey')
    localStorage.removeItem('userType')
    history.push("/login")
  }


  render() {
    return (
      <div>
        <Header handleClick={this._logout} image={logo} />
        <div className="main-content">
          <Sidebar image={logo} {...this.props} />
          <div className="content-body">
            <CustomBreadcrumb {...this.props} />
            <Switch>
              <Route path={this.props.match.url} exact={true} render={(props) => <DashboardView {...props} />} />

              <Route path={`${this.props.match.url}/vehicle`} exact={true} render={(props) => <Vehicle {...props} />} />
              <Route path={`${this.props.match.url}/vehicle/details`} exact={true} render={(props) => <VehicleDetail {...props} />} />
              <Route path={`${this.props.match.url}/tags`} exact={true} render={(props) => <Tags {...props} />} />
              <Route path={`${this.props.match.url}/settings`} exact={true} render={(props) => <Settings {...props} />} />

              {/* <Route path={`${this.props.match.url}/UserProfile`} exact={true} render={(props) => <UserProfile {...props} />} /> */}
              <Redirect to='/dashboard' />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard;