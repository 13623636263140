import React, { Component, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getTags, deallocateTag } from '../../../state/redux/actions/tags';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from '@emotion/styled';
import { data } from 'jquery';

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`
const StyledSelect = styled.select`
    width:200px;
`
const Stylediv = styled.div`
    display: flex;
    justify-content: flex-center;
    margin:10px
`



function Dallocation(props) {
    // constructor(props){
    //     super(props)
    //     this._handleFiles=this._handleFiles.bind(this)
    // }

    const tagsData = useSelector(state => state.tags.tags);
    const dispatch = useDispatch();
    const { vehicle } = props;
    const [vehicleD, setVehicleD] = useState({})
    const [selected, setSelected] = React.useState({ id: '', tid: '' });
    const [call, setCall] = useState(true)
    const [tags, setTags] = useState()
    const pending = (tag) => {
        return tag.Status === "Pending"
    }
    // const handleChange = (event) => {
    //     const name = event.target.name;
    //     const value = event.target.value;
    //     setSelected(values => ({ ...values, [name]: value }))
    //     console.log(selected, "[[[[[");
    // }

    const deAllocate = () => {
        dispatch(deallocateTag(selected))
        props.closeModal()
        // console.log(selected);
    }
    useEffect(() => {
        if (vehicle) {
            setVehicleD(vehicle)
        }
        if (call) {
            dispatch(getTags())

        }
        if (vehicleD) {
            selected.id = vehicleD._id;
        }
        if (vehicleD && vehicleD.Tid) {
            selected.tid = vehicle.Tid
        }
        if (call) {
            setCall(prev => !prev)
        }
        if (tagsData) {
            const available = tagsData.filter(pending)
            setTags(available);
        }
    }, [vehicle, tagsData, call, vehicleD])
    // console.log(tagsData, "hhhhhhhh");
    // console.log(tags, "qqqqqqqq");
    console.log(selected, "KKKKKKKKKKk");
    return (
        <section id="deAllocationModal" className="custom-modal">
            <div className="custom-modal-header">
                <h3>Alert</h3>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" >
                <h6>Are you sure you want to Deallocate tag to this
                </h6>
                <h6>Vehicle Number:{vehicleD.VehicleNumber}</h6>
                <h6>Tag Id:{vehicleD.Tid}</h6>
                <div className="row-div flex-jc-sb">
                    <div className="share-div">
                        <button className="action_btn" onClick={deAllocate}>Yes</button>
                    </div>
                    <div className="share-div">
                        <button className="action_btn" onClick={props.closeModal}>No</button>
                    </div>
                </div>
            </div>
        </section>
    )
}



export default Dallocation;