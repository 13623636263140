import { post } from "./../../../services/requests";
import { GET_CONFIG,ADD_CONFIG,EDIT_CONFIG } from "./types";

export const getConfigs = (obj) => {
    return (dispatch) => {
        post("common/get-config", obj)
            .then(response => {
                // console.log('res', response.data)
                dispatch({ type: GET_CONFIG, payload: response.data });
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};

export const addConfigs = (obj) => {
    return (dispatch) => {
        post("common/add-config", obj)
            .then(response => {
                console.log('res', response.message)
                dispatch({ type: ADD_CONFIG });
                dispatch(getConfigs())
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};

export const updateConfigs = (obj) => {
    return (dispatch) => {
        post("common/update-config", obj)
            .then(response => {
                console.log('res', response.message)
                dispatch({ type: EDIT_CONFIG });
                dispatch(getConfigs())
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};