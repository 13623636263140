import React, { useEffect, useState } from 'react'
import './../../static/css/login.css'
import bg from './../../static/images/loginBackground.jpg'
import axios from "axios";
import history from "../../services/history"
import { loginApi } from '../../state/redux/actions/login';
import { useDispatch, useSelector } from 'react-redux'



const Login = (props) => {
    const dispatch = useDispatch();
    const { login } = useSelector((state) => state.loginReducer)
    const [passState, setPassState] = useState({
        email: "",
        password: ""
    })

    const getData = () => {
        // axios
        //     .post("http://3.110.93.134:8992/api/user/login", passState)
        //     .then(data => {
        //         localStorage.setItem('secretkey', data.data.token)
        //         localStorage.setItem('userType', (passState.email))
        //         history.push('/dashboard')
        //     })
        //     .catch(error => console.log(error));
        dispatch(loginApi(passState))
    };

    const [info_icon, set_infoIcon] = useState("info")
    const [length, setLength] = useState("list-1")
    const [upper, setUpper] = useState("list-1")
    const [lower, setLower] = useState("list-1")
    const [special, setSpecial] = useState("list-1")
    const [number, setNumber] = useState("list-1")
    const [verify_icon, set_verify_icon] = useState("hide")

    const [recover_modal, set_recover_modal] = useState(false)
    const [otp_modal, set_otp_modal] = useState(false)
    const [password_modal, set_pass_modal] = useState(false)
    const [policies, setpolicies] = useState(false)
    const [eyes, setEyes] = useState("hide")
    const [passwordType, setPasswordType] = useState("password")

    const recover_function = () => {
        set_recover_modal(!recover_modal)
    }
    const close_recover_modal = () => {
        set_recover_modal(!recover_modal)
    }
    const open_otp_modal = () => {
        set_otp_modal(!otp_modal)
        set_recover_modal(!recover_modal)

    }
    const close_otp_modal = () => {
        set_otp_modal(!otp_modal)
    }
    const verifyAndOpen = () => {
        set_pass_modal(!password_modal)
        set_otp_modal(!otp_modal)
    }
    const close_password = () => {
        set_pass_modal(!password_modal)
        if (policies === true) {
            setpolicies(!policies)
        }
    }
    const show_policies = () => {
        setpolicies(!policies)
    }
    const close_policies = () => {
        setpolicies(!policies)
    }
    const handlePassword = (e) => {
        setPassState({ ...passState, [e.target.name]: e.target.value })
    }
    const togglePassType = () => {
        if (passwordType == "password") {
            setPasswordType("text")
        }
        else {
            setPasswordType("password")
        }

    }
    useEffect(() => {
        // toggle eye
        if (passState.length == 0) {
            setEyes("hide")
        }
        else {
            setEyes("show-eye")
        }
    }, [eyes])

    // useEffect(()=>{
    //     if(login){
    //         // history.push('/dashboard')
    //         // console.log(login,"logged in data from api")
    //     }
    // },[login])


    //     if (passState.newPass.match(/[a-z]/g) && passState.newPass.length > 8 && passState.newPass.match(/[A-Z]/g) && passState.newPass.match(/[0-9]/g) && passState.newPass.match(/[!@#$%^&*]/g)) {
    //         if (passState.newPass === passState.confirmPass) {
    //             set_verify_icon("verify")
    //         }
    //         else {
    //             set_verify_icon("hide")
    //         }
    //         set_infoIcon("green-info")
    //     }
    //     else {
    //         set_infoIcon("info")
    //     }

    //     // consitions for password policy
    //     if (passState.newPass.match(/[a-z]/g)) {
    //         setLower("green-list")
    //     }
    //     else {
    //         setLower("list-1")
    //     }

    //     if (passState.newPass.match(/[A-Z]/g)) {
    //         setUpper("green-list")
    //     }
    //     else {
    //         setUpper("list-1")
    //     }

    //     if (passState.newPass.length > 8) {
    //         setLength("green-list")
    //     }
    //     else {
    //         setLength("list-1")
    //     }
    //     if (passState.newPass.match(/[0-9]/g)) {
    //         setNumber("green-list")
    //     }
    //     else {
    //         setNumber("list-1")
    //     }

    //     if (passState.newPass.match(/[!@#$%^&*]/g)) {
    //         setSpecial("green-list")
    //     }
    //     else {
    //         setSpecial("list-1")
    //     }
    //     localStorage.setItem('userType', (passState.userID))
    console.log({ login })

    return (
        <>
            <div className='login-parent-div'>
                <img src={bg} alt='' />
                <div className='login-left'>
                    <div className='login-div'>
                        <span>Enterprise Login</span>
                        <div className='login-form'>
                            <div className='login-field-1'>
                                <div className='login-img'>
                                    <i id="user_pic" class="fa fa-user" aria-hidden="true"></i>
                                </div>


                                <div className='email-div'>
                                    <input id="input-field1" name="email" value={passState.email} onChange={(e) => handlePassword(e)} type="text" placeholder="E-MAIL/ UserID" />
                                </div>
                            </div>

                            <div className='login-field-2'>
                                <div className='lock-img'>
                                    <i id="lock-pic" class="fa fa-lock" aria-hidden="true"></i>
                                </div>

                                <div className='passwordDiv'>
                                    <input type={passwordType} name="password" value={passState.password} onChange={(e) => handlePassword(e)} id="input-field2" placeholder="PASSWORD" />
                                </div>
                                <div className='eye-div'>
                                    <i onClick={togglePassType} id={eyes} class="fa fa-eye" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div className='login-btn-group'>
                                <div className='btn-div'>
                                    {/* <button onClick={() => props._onLogin()}>LOGIN</button> */}
                                    <button onClick={getData}>LOGIN</button>
                                    {/* <button onClick={recover_function}>FORGOT PASSWORD?</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='login-right'></div>
            </div>

            {/* {recover_modal && (
                <div className='recover-moadal'>
                    <div className='recover-overlay' onClick={close_recover_modal}></div>
                    <div className='recover-content'>
                        <div className='recover_data'>
                            <div className='inner-div'>
                                <div className='recover-heading'>
                                    <span id="heading-style"> Recover Password</span>
                                    <i onClick={close_recover_modal} id="heading-cross" class="fa fa-times" aria-hidden="true"></i>
                                </div>

                                <div className='recover-input'>
                                    <div className='recover-input-field'>
                                        <input type="email" placeholder="Enter your registered email to recover password." />
                                    </div>
                                </div>

                                <div className='recover-button'>
                                    <div className='btn-element'>
                                        <button onClick={open_otp_modal}> Send OTP</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {otp_modal && (
                <div className='recover-moadal'>
                    <div className='recover-overlay' onClick={close_otp_modal}></div>
                    <div className='recover-content'>
                        <div className='recover_data'>
                            <div className='inner-div'>
                                <div className='recover-heading'>
                                    <span id="heading-style"> Recover Password</span>
                                    <i onClick={close_otp_modal} id="heading-cross" class="fa fa-times" aria-hidden="true"></i>
                                </div>

                                <div className='recover-input'>
                                    <div className='otp-input'>
                                        <div className='input-1'>
                                            <input className='otp-field' />
                                        </div>
                                        <div className='input-2'>
                                            <input className='otp-field' />
                                        </div>
                                        <div className='input-3'>
                                            <input className='otp-field' />
                                        </div>
                                        <div className='input-4'>
                                            <input className='otp-field' />
                                        </div>
                                    </div>
                                </div>

                                <div className='recover-button'>
                                    <div className='btn-element'>
                                        <button onClick={verifyAndOpen} > Verify OTP</button>
                                        <span className='resend'>Didn't receive it yet?<span id="resend-style"> Resend OTP</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {password_modal && (
                <div className='password-moadal'>
                    <div className='password-overlay'></div>
                    <div className='password-content'>
                        <div className='password_data'>
                            <div className='password-div'>

                                <div className='recover-heading'>
                                    <span id="heading-style"> Recover Password</span>
                                    <i onClick={close_password} id="heading-cross" class="fa fa-times" aria-hidden="true"></i>
                                </div>

                                <div className='recover-pass-input'>
                                    <div className='pass-inner-div'>
                                        <div className='pass-top-div'>
                                            <div className='new-pass'>
                                                <input name="newPass" value={passState.newPass} type="text" placeholder="Enter New Password" onChange={(e) => handlePassword(e)} />
                                            </div>
                                            <div className='requirements'>
                                                <i onClick={show_policies} id={info_icon} class="fa fa-info-circle" aria-hidden="true"></i>
                                            </div>
                                        </div>

                                        <div className='pass-bottom-div'>
                                            <div className='confirm-pass'>
                                                <input name="confirmPass" value={passState.confirmPass} type="text" placeholder="Confirm Password" onChange={(e) => handlePassword(e)} />
                                            </div>

                                            <div className='right-tick'>
                                                <i id={verify_icon} class="fa fa-check" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='recover-button'>
                                    <div className='btn-element'>
                                        <button > Change Password </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}

            {policies && (
                <div className='policy-moadal'>
                    <div className='policies-content'>
                        <div className='policies-div'>
                            <div className='policies_list'>
                                <i onClick={close_policies} id="policy-cross" class="fa fa-times" aria-hidden="true"></i>
                                <ul>
                                    <li className={length}>Password must have minimum 8 Characters.</li>
                                    <li className={upper}>Password must have atleast one Uppercase Character.</li>
                                    <li className={lower}>Password must have atleast one Lowercase Character.</li>
                                    <li className={special}>Password must have atleast one special(@#$%) Character.</li>
                                    <li className={number}>Password must have atleast one Numeric Character.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </>

    );
};

export default Login;