import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import history from './../../services/history';

//InActive
import DashboardIcon from './../../static/images/icon/sidebar/updated/dashboard.svg'
import BookingIcon from './../../static/images/icon/sidebar/updated/booking.svg'
import VehicleIcon from './../../static/images/icon/sidebar/updated/vehicles.svg'
import ReceiptIcon from './../../static/images/icon/sidebar/updated/goods_receipt.svg'
import AddressIcon from './../../static/images/icon/sidebar/updated/address_book.svg'
import HubIcon from './../../static/images/icon/sidebar/updated/hubs.svg'
import DriverIcon from './../../static/images/icon/sidebar/updated/driver.svg'
import PoDIcon from './../../static/images/icon/sidebar/updated/pod.svg'
import AnalyticIcon from './../../static/images/icon/sidebar/updated/analytics.svg'
import ContractIcon from './../../static/images/icon/sidebar/updated/contracts.svg'
import settings from './../../static/images/icon/sidebar/settings.png'

//Active
import DashboardActiveIcon from './../../static/images/icon/sidebar/updated/dashboard_active.svg'
import BookingActiveIcon from './../../static/images/icon/sidebar/updated/booking_active.svg'
import VehicleActiveIcon from './../../static/images/icon/sidebar/updated/vehicles_active.svg'
import ReceiptActiveIcon from './../../static/images/icon/sidebar/updated/goods_receipt_active.svg'
import AddressActiveIcon from './../../static/images/icon/sidebar/updated/address_book_active.svg'
import HubActiveIcon from './../../static/images/icon/sidebar/updated/hubs_active.svg'
import DriverActiveIcon from './../../static/images/icon/sidebar/updated/driver_active.svg'
import PoDActiveIcon from './../../static/images/icon/sidebar/updated/pod_active.svg'
import AnalyticActiveIcon from './../../static/images/icon/sidebar/updated/analytics_active.svg'
import ContractActiveIcon from './../../static/images/icon/sidebar/updated/contracts_active.svg'
import settingsActive from './../../static/images/icon/sidebar/settingsActive.png'

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            url: `${props.location.pathname}${props.location.search}${props.location.hash}`
        }
    }

    componentDidMount() {
        this.unlisten = history.listen((location, action) => {
            this.setState({
                url: `${location.pathname}${location.search}${location.hash}`
            })
        })
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return (
            <div className="sidebar open">
                <div className="tab-content">
                    <div className="tab-pane" id="mainmenu">
                        <ul className="menu-nav">
                            <li className={this.state.url === "/dashboard" ? "active" : ""}>
                                <Link to="/dashboard">
                                    <img className='sicon' src={this.state.url === "/dashboard" ? DashboardActiveIcon : DashboardIcon} alt='' />
                                    <span className='txt'>Dashboard</span>
                                </Link>
                            </li>
                            <li className={this.state.url.includes("/dashboard/vehicle") ? "active" : ""}>
                                <Link to="/dashboard/vehicle">
                                    <img className='sicon' src={this.state.url.includes("/dashboard/vehicle") ? VehicleActiveIcon : VehicleIcon} alt='' />
                                    <span className='txt'>Vehicles</span>
                                </Link>
                            </li>
                            <li className={this.state.url.includes("/dashboard/Tags") ? "active" : ""}>
                                <Link to="/dashboard/Tags">
                                    <img className='sicon' src={this.state.url.includes("/dashboard/Tags") ? ContractActiveIcon : ContractIcon} alt='' />
                                    <span className='txt'>RFID Tags</span>
                                </Link>
                            </li><li className={this.state.url.includes("/dashboard/settings") ? "active" : ""}>
                                <Link to="/dashboard/settings">
                                    <img className='sicon' style={{width:"25%",marginLeft:"11px"}} src={this.state.url.includes("/dashboard/settings") ? settingsActive : settings} alt='' />
                                    <span className='txt'>Settings</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar;