import React, { Component } from 'react';
import Footer from "../../views/Landing/Footer"
import Navbar from "../../views/Landing/Navbar"
import Login from "../../views/Landing/Login"
import './../../static/css/style.css'
import logo from './../../static/images/logo.png'

import history from '../../services/history'

class App extends Component {
  constructor(props) {
    super(props)
    this._onLogin = this._onLogin.bind(this)
  }

  _onLogin() {
    localStorage.setItem('secretkey', "ramshankar")
    history.push('/dashboard')
  }

  render() {
    return (
      <div>
        <Navbar logo={logo} />
        <Login _onLogin={this._onLogin} />
        <Footer />
      </div>
    )
  }
}

export default App;