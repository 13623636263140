import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { getConfigs } from "./../../state/redux/actions/settings"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@material-ui/core/IconButton'
import view_grey from './../../static/images/icon/view_grey.png'
import AddConfigs from './Modal/Addconfigs';
import ViewConfigs from './Modal/ViewConfigs';
import EditConfigs from './Modal/EditConfigs';
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/system';

const StyledTableRow = styled(TableRow)`
background-color: #FF7200;
`
const StyledCell = styled(TableCell)`
color:white;
border-right:1px solid #fff
`
const StyledCell2 = styled(TableCell)`
border-right:1px solid #C5C5C5
`

function closeView() {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
    $(".custom-modal").removeClass("custom-modal-is-visible")
}
function toogleView(index, check = true) {
    if ($(".collapse-view").eq(index).hasClass("open") && check) {
        $(".collapse-view").eq(index).removeClass("open");
        $(".collapse-view").eq(index).animate({ height: 35 }, 500);
    } else {
        let height =
            Number($(".collapse-view").eq(index).children("table").height()) + 2;
        $(".collapse-view")
            .eq(index)
            .animate({ height }, 500, () => {
                $(".collapse-view").eq(index).addClass("open");
            });
    }
}
function Settings() {
    const dispatch = useDispatch();
    const [configs, setConfigs] = useState();
    const settingData = useSelector(state => state.configs.configs);
    function _handleClick(id, row) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
        setConfigs(row)
    }
    useEffect(() => {
        dispatch(getConfigs())
    }, [])


    return (
        <div className='contentpanel'>
            <h4>Configurations</h4>
            <div className='cust-row flex-jc-end'>
                <button className='btn btn-shadow btn-secondary ' onClick={() => { _handleClick('addconfigsModal') }} >Add Configurations</button>
            </div>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledCell>S.No</StyledCell>
                            <StyledCell align='center' width={"35%"}>IMEI Number</StyledCell>
                            <StyledCell align='center' width={"20%"}>Name</StyledCell>
                            <StyledCell align='center' width={"20%"}>Power</StyledCell>
                            <StyledCell align='center' width={"25%"}>Actions</StyledCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {settingData && (settingData.map((row, index) => (
                            <TableRow key={row.number}>
                                <StyledCell2 component="th" scope="row">
                                    {index + 1}
                                </StyledCell2>
                                <StyledCell2 align='center' width={"35%"}>{row.imei}</StyledCell2>
                                <StyledCell2 align='center' width={"20%"}>{row.Displayname}</StyledCell2>
                                <StyledCell2 align='center' width={"20%"}>{row.power}</StyledCell2>
                                <StyledCell2 align='center' width={"25%"}>{<IconButton
                                // onClick={() => _handleClick('viewVehicleModal', row)}

                                >{<img style={{ height: "20px" }} src={view_grey} onClick={() => { _handleClick('viewconfigsModal', row) }} />}</IconButton>}
                                {<IconButton
                                // onClick={() => _handleClick('viewVehicleModal', row)}
                                onClick={() => { _handleClick('editconfigsModal', row) }}
                                >{<i className="fa fa-pencil-square-o">  </i>}</IconButton>}</StyledCell2>
                            </TableRow>

                        )))}
                    </TableBody>
                </Table>
            </TableContainer>
            <AddConfigs closeModal={closeView} toogleView={toogleView} />
            <ViewConfigs configs={configs} closeModal={closeView} toogleView={toogleView} />
            <EditConfigs configs={configs} closeModal={closeView} toogleView={toogleView} />
        </div>
    )

}
export default Settings;