import React, { Component, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment'

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
`


function ViewVehicle(props) {
    // constructor(props){
    //     super(props)
    //     this._handleFiles=this._handleFiles.bind(this)
    // }
    const { vehicle } = props;
    const [vehicleD, setVehicleD] = useState({})
    useEffect(() => {
        if (props.vehicle) {
            setVehicleD(vehicle)
        }
    }, [vehicle])
    console.log('vehicleD', vehicleD);
    return (
        <section id="viewVehicleModal" className="custom-modal modal-vehi-add">
            <div className="modal-head-vehi">
                <h3>Vehicle Details</h3>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" >
                {vehicleD && (
                    <div>
                        <div className="view-row">
                            <div className='view-ro1'>
                                <span className='span-head'>Vehicle Number</span>
                                <span className='span-txt'>{vehicleD.VehicleNumber || "NA"}</span>

                            </div>

                            <div className='view-ro1'>
                                <span className='span-head'>Bay Number</span>
                                <span className='span-txt'>{vehicleD.BayNumber || "NA"}</span>
                            </div>
                        </div>
                        <div className="view-row">
                            <div className='view-ro1'>
                                <span className='span-head'>Product Name</span>
                                <span className='span-txt'>{vehicleD.ProductName || "NA"}</span>
                            </div>

                            <div className='view-ro1'>
                                <span className='span-head'>LR Number</span>
                                <span className='span-txt'>{vehicleD.LrNumber || "NA"}</span>
                            </div>
                        </div>
                        <div className="view-row">
                            <div className='view-ro1'>
                                <span className='span-head'>Work Type</span>
                                <span className='span-txt'>{vehicleD.WorkType || "NA"}</span>
                            </div>
                            <div className='view-ro1'>
                                <span className='span-head'>Gate Entry Time</span>
                                <span className='span-txt'>{vehicleD.GatedInTime ? moment(vehicleD.GatedInTime).format('DD/MM/YYYY hh:mm A') : "NA"}</span>
                            </div>

                        </div>
                        <div className="view-row">
                            <div className='view-ro1'>
                                <span className='span-head'>Bay Entry Time</span>
                                <span className='span-txt'>{vehicleD.BayInTime ? moment(vehicleD.BayInTime).format('DD/MM/YYYY hh:mm A') : "NA"}</span>
                            </div>
                            <div className='view-ro1'>
                                <span className='span-head'>Bay Exit Time</span>
                                <span className='span-txt'>{vehicleD.BayOutTime ? moment(vehicleD.BayOutTime).format('DD/MM/YYYY hh:mm A') : "NA"}</span>
                            </div>

                        </div>
                        <div className="view-row">
                            <div className='view-ro1'>
                                <span className='span-head'>Gate Exit Time</span>
                                <span className='span-txt'>{vehicleD.GateOutTime ? moment(vehicleD.GateOutTime).format('DD/MM/YYYY hh:mm A') : "NA"}</span>
                            </div>
                            <div className='view-ro1'>
                                <span className='span-head'>Time at Bay</span>
                                <span className='span-txt'>{vehicleD.BayTime ? (vehicleD.BayTime.replace("Hours",":").replace("minutes","hrs")) : "NA"}</span>
                            </div>

                        </div>
                        <div className="view-row">
                            <div className='view-ro1'>
                                <span className='span-head'>Time at Warehouse</span>
                                <span className='span-txt'>{vehicleD.GateOutTime ? (vehicleD.WarehouseTime.replace("Hours",":").replace("minutes","hrs")) : "NA"}</span>
                            </div>
                        </div>
                    </div>
                )}
                {/* <div className="row-div">
                    <div className="share-div">
                        <button className="action_btn">Add Vehicle</button>
                    </div>
                </div> */}
            </div>
        </section>
    )
}



export default ViewVehicle;