import { post } from "./../../../services/requests";
import { GET_XL } from "./types";

export const getExcel = (obj) => {
    return (dispatch) => {
        post("vehicle/vehiclelist", obj)
            .then(response => {
                // console.log('res', response.data)
                dispatch({ type: GET_XL, payload: response.data });
                const downloadLink = document.createElement("a");
                downloadLink.href = response.data;
                // downloadLink.target = "_blank";
                downloadLink.click();
                window.location.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data
            })
            .catch(err => {
                console.log('res', err)
            })
    }
};