import React, { Component, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { addTag } from "../../../state/redux/actions/tags"
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack'


function AddRfid(props) {
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();



    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
        console.log(inputs, "[[[[[");
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(addTag(inputs))
        props.closeModal()
    }


    function _handleFiles(files) {
        console.log(files)
    }


    return (
        <section id="addVehicleModal" className="custom-modal">
            <div className="custom-modal-header">
                <h1>Add RFID</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" >

                <form onSubmit={handleSubmit}>
                    


                        <Stack spacing={2} direction="column">
                            <TextField id="outlined-basic" label="Enter Tag id" name="tid" value={inputs.tid || ""} variant="outlined" onChange={handleChange} size='small' />
                         
                            <TextField id="outlined-basic" label="Enter Tag Number" name="tagNo" value={inputs.tagNo || ""} variant="outlined" onChange={handleChange} size='small' />
                            
                            <TextField id="outlined-basic" label="Enter Tag Name" name="displayName" value={inputs.displayName || ""} variant="outlined" onChange={handleChange} size='small' />

                            
                        </Stack>
                    
                    <div className="row-div">
                        <div className="share-div gx-mt-10 add-vehi-bottom add-vehi-button">
                            <input type="submit" className="add-btn add-button" />
                        </div>
                    </div>
                </form>
                {/* 
                <div className="row-div">
                    <div className="share-div">
                        <button className="action_btn">Add Rfid</button>
                    </div>
                </div> */}
            </div>
        </section>
    )
}



export default AddRfid;