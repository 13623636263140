import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import history from './../../services/history';

class CustomBreadcrumb extends Component{
    constructor(props) {
        super(props);
        let array=`${props.location.pathname}`.split("/")
        array.splice(0,1)
        this.state={
            breadcrumbs:array
        } 
        this._goTo=this._goTo.bind(this)    
    }

    componentDidMount() {
        this.unlisten = history.listen((location, action) => {
            let array=`${location.pathname}`.split("/")
            array.splice(0,1)
            this.setState({
                breadcrumbs:array
            })
        })
    }

    componentWillUnmount() {
          this.unlisten();
    } 
    
    _goTo(index){
        let str=""
        for(let j=0;j<=index;j++){
            str+=`/${this.state.breadcrumbs[j]}`
        }
        return str;
    }

    render() {
        return (
            <ol className="cust-breadcrumb">
                {
                    this.state.breadcrumbs.map((item,index)=>{
                        return <li key={index} className={this.state.breadcrumbs.length-1===index?'active':''}><Link to={this._goTo(index)}><i className={index===0?'fa fa-home mr5':''}></i> {item.replace(/-/g," ").toUpperCase()}</Link></li>
                    })
                }
            </ol>
        )
    }
}

export default CustomBreadcrumb;